import request from "@/utils/request.js"

/**
 * @func getMyCircle
 * @desc 获取我管理的圈子
 * @param {}  
 * @return {} 
 */
export function getMyCircle(params){
  return request({
    url: '/app-api/conn/group/admin/group/page',
    method: 'get',
    params
  })
}

/**
 * @func createCircle
 * @desc 创建圈子
 * @param {}  
 * @return {} 
 */
export function createCircle(data){
  return request({
    url: '/app-api/conn/group/admin/group',
    method: 'post',
    data
  })
}

// 获取圈子行业字典
export function getMomentsType() {
  return request({
    url: `/app-api/system/dict-data/values?dictType=fy_class`,
    method: 'get'
  })
} 
  

// 获取圈子动态
export function getCircleActive(params) {
  return request({
    url: `/app-api/conn/group/admin/moments/page`,
    method: 'get',
    params
  })
}

// 更新圈子动态
export function editActive(data) {
  return request({
    url: `/app-api/conn/group/admin/moments/essence`,
    method: 'put',
    data
  })
}

// 获取圈主信息
export function getCircleOwner(groupId) {
  return request({
    url: `/app-api/conn/group/admin/group/owner?groupId=${groupId}`,
    method: 'get'
  })
}

// 更新圈子
export function editCircle(data) {
  return request({
    url: `/app-api/conn/group/admin/group`,
    method: 'put',
    data
  })
}

// 获取圈子详情
export function getCircleDetail(groupId) {
  return request({
    url: `/app-api/conn/group/admin/group?groupId=${groupId}`,
    method: 'get'
  })
}
  
// 获取圈子加入管理规则
export function getJoinRule(groupId) {
  return request({
    url: `/app-api/conn/group/admin/group/join/rule?groupId=${groupId}`,
    method: 'get'
  })
}

// 修改圈子加入管理规则
export function editJoinRule(data) {
  return request({
    url: `/app-api/conn/group/admin/group/join/rule`,
    method: 'put',
    data
  })
}

// 圈子管理-分页获取/搜索圈子用户列表
export function getCircleUser(params) {
  return request({
    url: `/app-api/conn/group/admin/group/user/page`,
    method: 'get',
    params
  })
}

// 获取激活码列表
export function getCode(params) {
  return request({
    url: `/app-api/conn/group/admin/ticket/page`,
    method: 'get',
    params
  })
}

// 动态屏蔽/隐藏
export function activeHidden(data) {
  return request({
    url: `/app-api/conn/group/admin/moments/hidden`,
    method: 'put',
    data
  })
}

// 动态加精华/取消
export function activeEssence(data) {
  return request({
    url: `/app-api/conn/group/admin/moments/essence`,
    method: 'put',
    data
  })
}

// 按id查询用户列表
export function getUserById(code) {
  return request({
    url: `/app-api/conn/group/admin/user/get?code=${code}`,
    method: 'get'
  })
}



// 获取结算情况
export function getBalanceData(params) {
  return request({
    url: `/app-api/conn/group/admin/balance/data`,
    method: 'get',
    params
  })
}

// 获取今日实时数据
export function getTodayData(params) {
  return request({
    url: `/app-api/conn/group/admin/today/data`,
    method: 'get',
    params
  })
}

// 移入/移出黑名单
export function changeBlockList(data) {
  return request({
    url: `/app-api/conn/group/admin/group/user/block`,
    method: 'post',
    data
  })
}

// 获取套餐
export function getPackage(params) {
  return request({
    url: `/app-api/conn/trade/goods/list`,
    method: 'get',
    params
  })
}

// 获取套餐
export function buyActiveCode(data) {
  return request({
    url: `/app-api/conn/group/admin/trade/ticket`,
    method: 'post',
    data
  })
}
// 获取提现记录
export function getTradeRecord(params) {
  return request({
    url: `/app-api/conn/group/admin/trade/record/page`,
    method: 'get',
    params
  })
}
// 申请提现
export function applyTrade(data) {
  return request({
    url: `/app-api/conn/group/admin/trade/checkout/apply`,
    method: 'post',
    data
  })
}
