<template>
  <div id="app">
    <router-view/>
    <!-- <van-tabbar v-model="active" @change="onChange" active-color="#14BE98" v-show="isShowTab">
      <van-tabbar-item>
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? iconObj.dataActive : iconObj.dataIcon" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item>
        <span>圈子管理</span>
        <template #icon="props">
          <img :src="props.active ? iconObj.managerActive : iconObj.managerIcon" />
        </template>
      </van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>

const dataActive = require('./assets/icon/dataActive.png')
const dataIcon = require('./assets/icon/data.png')
const managerActive = require('./assets/icon/managerActive.png')
const managerIcon = require('./assets/icon/manager.png')
export default {
  name: "App",
  data(){
    return {
      // isShowTab: true,
      iconObj: {
        dataActive: dataActive, 
        dataIcon: dataIcon,
        managerActive: managerActive,
        managerIcon: managerIcon,
      },
      active: 0
    }
  },
  computed:{
    isShowTab(){
      let result = true
      if(this.$route.path == '/mineIndex' || this.$route.path == '/' || this.$route.path == '/index'){
        result = true
        if(this.$route.path == '/mineIndex'){
          this.active = 1
        } else {
          this.active = 0
        }
      }else{
        result = false
      }
      this.$forceUpdate()
      return result
    }
  },
  // watch:{
  //   $route: {
  //     handler(a,b){
  //       console.log('a,b',a,b)
  //       if(a.path == '/mineIndex' || a.path == '/' || a.path == '/index'){
  //         this.isShowTab = true
  //         if(a.path == '/mineIndex'){
  //           this.active = 1
  //         } else {
  //           this.active = 0
  //         }
  //       }else{
  //         this.isShowTab = false
  //       }
  //     },
  //     deep: true
  //   }
  // },
  methods:{
    onChange(e){
      if(e === 1){
        this.$router.push('/mineIndex')
      }else{
        this.$router.push('/')
      }
    }
  }
}
</script>

<style>

</style>
