import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

// const originalPush = VueRouter.prototype.push

// VueRouter.prototype.push = function push(location, resolve, reject) {
//   if ( resolve || reject ) return originalPush.call(this, location, resolve, reject)
//   return originalPush.call(this, location).catch((e)=>{})
// }


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },{
    path: '/moneyDetail',
    name: 'moneyDetail',
    component: () => import('@/views/activationCode/moneyDetail.vue')
  },{
    path: '/tx',
    name: 'tx',
    component: () => import('@/views/activationCode/tx.vue')
  },{
    path: '/mineIndex',
    name: 'mineIndex',
    component: () => import('@/views/mine/index.vue')
  },{
    path: '/buyActivationCode',
    name: 'buyActivationCode',
    component: () => import('@/views/activationCode/buyActivationCode.vue')
  },{
    path: '/activationCode',
    name: 'activationCode',
    component: () => import('@/views/activationCode/index.vue')
  },{
    path: '/joinCircleManager',
    name: 'joinCircleManager',
    component: () => import('@/views/mine/joinCircleManager.vue')
  },{
    path: '/addCircle',
    name: 'addCircle',
    component: () => import('@/views/mine/addCircle.vue')
  },{
    path: '/circleDetail',
    name: 'circleDetail',
    component: () => import('@/views/mine/circleDetail.vue')
  },{
    path: '/addCircle',
    name: 'addCircle',
    component: () => import('@/views/mine/addCircle.vue')
  },{
    path: '/userManager',
    name: 'userManager',
    component: () => import('@/views/mine/userManager.vue')
  },{
    path: '/inviteUser',
    name: 'inviteUser',
    component: () => import('@/views/mine/inviteUser.vue')
  },{
    path: '/overdueUser',
    name: 'overdueUser',
    component: () => import('@/views/mine/overdueUser.vue')
  },{
    path: '/activeManager',
    name: 'activeManager',
    component: () => import('@/views/mine/activeManager.vue')
  },{
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },{
    path: '/mobileLogin',
    name: 'mobileLogin',
    component: () => import('@/views/mobileLogin.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
