import Cookies from 'js-cookie'

export function getToken(TokenKey = 'accessToken') {
  return Cookies.get(TokenKey)
}

export function setToken(token, TokenKey = 'accessToken') {
  return Cookies.set(TokenKey, token)
}

export function removeToken(TokenKey = 'accessToken') {
  return Cookies.remove(TokenKey)
}
