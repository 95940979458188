import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { getToken } from '@/utils/auth.js'
import Vant, { ImagePreview, Toast } from 'vant'
import 'vant/lib/index.css'
import VConsole from 'vconsole'
var Vconsole = new VConsole()

Vue.config.productionTip = false
Vue.prototype.$toast = Toast
Vue.prototype.$imagePreview = ImagePreview

Vue.use(Vant);
Vue.use(Vconsole)


router.beforeEach((to, from, next) => {
  // 检查用户是否已登录  
  if (to.name != 'login' && !getToken()) {
    // 如果用户未登录，并且不是访问登录页面，则重定向到登录页面  
    next({ name: 'login', path: '/login' });
  } else {
    next();
  }
});



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
