import request from "@/utils/request.js"

/**
 * @func getPhoneCode
 * @desc 获取手机验证码
 * @param {}  
 * @return {} 
 */
export function getPhoneCode(data){
  return request({
    url: '/app-api/member/auth/send-sms-code',
    method: 'post',
    data
  })
}

export function login(data){
  return request({
    url: '/app-api/member/auth/sms-login',
    method: 'post',
    data
  })
}
export function logout(){
  return request({
    url: '/app-api/member/auth/logout',
    method: 'post'
  })
}

export function getRedirectUrl(params){
  return request({
    url: '/app-api/member/auth/social-auth-redirect',
    method: 'get',
    params
  })
}

export function getOpenIdByCode(data){
  return request({
    url: '/app-api/member/auth/social-login',
    method: 'post',
    data
  })
}

export function socialBinding(data){
  return request({
    url: '/app-api/member/auth/social-binding',
    method: 'post',
    data
  })
}

