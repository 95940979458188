<template>
  <div class="mineIndex">
    <div class="logout-wrap">
      <div class="logout-left">
        <div style="margin-bottom: 16rem">登录账号：18888888888</div>
        <div>可创建圈子数：1</div>
      </div>
      <div class="logout-btn" @click="goLogout">退出登录</div>
    </div>
    <div class="info-wrap" style="padding: 12px 16px" @click="intoActivationCode">
      <div class="arrow-wrap">
        <div class="arrow-wrap-left">激活码管理</div>
        <div class="arrow-wrap-right">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <div class="info-wrap" style="padding: 12px 16px" @click="$router.push('/buyActivationCode')">
      <div class="arrow-wrap">
        <div class="arrow-wrap-left">购买激活码</div>
        <div class="arrow-wrap-right">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>

    <div class="circle-item" @click="clickCircleItem(item.id)" v-for="(item,index) in circleData" :key="index">
      <div class="into-wrap">
        <div class="circle-title">
          <div class="circle-title-tag">
            {{ item.industryName }}
          </div>
          <span class="circle-title-span">
            {{ item.name }}
          </span>
        </div>
        <div class="circle-img">
          <img :src="item.avatar" style="height:100%;width:100%;" alt="">
        </div>
        <div class="circle-desc">
          <span style="margin-right: 10rem;">微群：{{ item.qrcodeCount }}个</span>·
          <span style="margin:0 10rem;">人脉：{{ item.personCount }}个</span>·
          <span style="margin-left: 10rem;">动态：{{ item.momentsCount }}个</span>
        </div>
      </div>
      <div class="bottom-btn">
        <div class="bottom-btn-item" style="border-right: 1px solid #EEEEEE;" @click.stop="intoDetail(0,item.id)">查看数据</div>
        <div class="bottom-btn-item" @click.stop="intoDetail(1,item.id)">管理圈子</div>
      </div>
    </div>

    <div class="add-wrap">
      <div class="add-img-wrap">
        <img src="../assets/icon/addIcon.png" alt="">
      </div>
      <div class="add-text" @click="createCircle">
        创建圈子
      </div>
    </div>
    <div style="height: 50px;width: 100%;"></div>
  </div>
</template>

<script>
  import { getMyCircle } from "@/api/circle.js"
  import { logout, getOpenIdByCode, socialBinding } from "@/api/login.js"
  import { Dialog } from 'vant';
  import { removeToken } from '@/utils/auth'
  import { setToken } from '@/utils/auth.js'
  document.title = '微群圈列表'
  export default {
    name: 'index',
    data(){
      return {
        circleData:[],
      }
    },
    methods: {
      intoActivationCode(){
        this.$router.push('/activationCode')
      },
      goLogout(){
        Dialog.confirm({
          title: '提示',
          message: '是否退出登录',
          confirmButtonColor:'#14be98'
        }).then(() => {
          logout().then(res => {
            if(res.code === 0){
              removeToken('refreshToken')
              removeToken('accessToken')
              this.$router.push('/login')
            }else{
              this.$toast(res.msg)
            }
          })
        }).catch(() => {

        });
      },
      clickCircleItem(connGroupId){
        this.$router.push({path: '/circleDetail', query: {type: 0, connGroupId }})
      },
      createCircle(){
        this.$router.push('/addCircle')
      },
      intoDetail(type, connGroupId){
        this.$router.push({ path:'/circleDetail', query: { type, connGroupId } })
      },
      getParams (name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
        var r = window.location.search.substr(1).match(reg)
        if (r != null) return unescape(r[2])
        return null
      },
      bindingWechart(code, state){
        socialBinding({type: 31, appid: 'wx306e27131d484e04', code, state}).then(res => {
          if(res.code == 0){

          }
        })
      }
    },
    created(){
      console.log(location.search)
      let code = this.getParams('code')
      let state = this.getParams('state')
      if(code){
        this.bindingWechart(code, state)
      }
      getMyCircle({pageNo: 1, pageSize: 100}).then(res =>{
        if(res.code === 0){
          this.circleData = res.data.list
        }else{
          
        }
      })
    },
  }
</script>

<style lang='scss' scoped>
  .mineIndex {
    width: calc(100vw - 32px);
    padding: 16px;
    font-size: 28rem;
    height: 100vh;
    background-color: #F7F8F9;

    .logout-wrap{
      border-radius: 20rem;
      width: calc(100% - 56rem);
      display: flex;
      justify-content: space-between;
      padding: 24rem 28rem; 
      align-items: center;
      background-color: #14BE98;
      margin-bottom: 24rem;
      font-size: 24rem;
      .logout-left{
        color: #fff;
      }
      .logout-btn{
        background-color: #FFF;
        color: #14BE98;
        padding: 16rem 24rem;
        border-radius: 20rem;
        font-weight: 600;
      }
    }

    .info-wrap {
      padding: 32rem;
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 32rem; 
      font-size: 28rem;

      .arrow-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .arrow-wrap-left {
          width: calc(100% - 50px);
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          color: #333333;
        }
        .arrow-wrap-right {
          width: 50px;
          text-align: right;
        }
      }
    }

    
    .circle-item {
      width: calc(100% - 56rem);
      padding: 28rem 28rem 24rem;
      border-radius: 20rem;
      background: #FFFFFF;
      margin-bottom: 24rem;

      .into-wrap{
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 120rem;

        .circle-title {
          width: calc(100% - 130rem);
          height: 88rem;
          text-overflow: ellipsis;
          overflow:hidden;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-height: 40rem;
          .circle-title-tag{
            margin-right: 12rem;
            background: #588BF9;
            border-radius: 6rem;
            font-size: 20rem;
            color: #FFF;
            height: 44rem;
            line-height: 44rem;
            padding: 0 14rem;
            display: inline-block;
          }
  
          .circle-title-text {
            font-size: 30rem;
            font-weight: bold;
            color: #333;
          }
        }
        .circle-img {
          width: 120rem;
          height: 120rem;
          border-radius: 10rem;
          overflow: hidden;
          .img {
            width: 100%;
            height: 100%;
          }
        }
        .circle-desc {
          position:absolute;
          bottom: 0px;
          color: #999999;
          font-size: 24rem;
        }
      }
      .bottom-btn{

        margin-top: 24rem;
        display: flex;
        border-top: 1px solid #EEEEEE;

        .bottom-btn-item{
          width: 50%;
          margin-top: 24rem;
          text-align: center;
          color: #14BE98;
        }
      }
      .advert-btn {
        width: 92rem;
        height: 48rem;
        color: #14BE98;
        text-align: center;
        line-height: 48rem;
        background-color: #fff;
      }
    }
    .add-wrap{
      height: 176rem;
      border-radius: 20rem;
      margin-bottom: 24rem;
      border: 1px dashed #14BE98;
      width: 100%;
      position: relative;
      color: #14BE98;
      text-align: center;
      box-sizing: border-box;

      .add-img-wrap{

        img{
          width: 30px;
          height: 30px;
          margin-top: 16px;
          margin-bottom: 12px;
        }
      }
      .add-text{

      }
    }
  }
</style>